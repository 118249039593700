<template>


      
      <header-component></header-component>

      <div class="container">
        <div class="row mt-2">
          <div class="col">
            <transition enter-active-class="animate__animated animate__fadeIn">
              <router-view/>
            </transition>
          </div>
        </div>
      </div>
      

 
  
</template>

<script>

import HeaderComponent from "./components/HeaderComponent.vue";

export default {
  name:'App',
  components:{
    HeaderComponent
  }
}

</script>

<style scoped>

</style>