<template>
    <div id="header">
        <nav class="navbar navbar-expand-lg bg-dark text-light" data-bs-theme="dark">
            <div class="container-fluid">
                
                <router-link  class="navbar-brand" to="/">FILMARIA</router-link>
                
                <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
                
                <div class="collapse navbar-collapse" id="navbarNavDropdown">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <router-link :to="{name:'home'}" class="nav-link" active-class="active">Home</router-link>
                        </li>
                        <li class="nav-item">
                            <router-link :to="{path: '/meus-filmes'}" class="nav-link" active-class="active">Meus Filmes</router-link>
                        </li>
                    </ul>
                </div>

            </div>
        </nav>
    </div>  
</template>

<script>
export default {
    name: 'HeaderComponent'
}
</script>

<style scoped>

</style>
