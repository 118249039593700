import { createRouter, createWebHistory } from 'vue-router'

const Home = () => import('../views/HomeView.vue');
const MeusFilmes = () => import('@/views/MeusFilmesView.vue');
const Filme = () => import('@/views/FilmeView.vue');
const Erro = () => import('@/views/ErroView.vue');

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/meus-filmes',
    name: 'filmes',
    component: MeusFilmes
  },
  {
    path: '/filme/:id',
    name: 'filme',
    component: Filme,
  },
  {
    path: '/:catchAll(.*)*', 
    component: Erro
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
